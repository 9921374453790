.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 30px;

  .section {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}
