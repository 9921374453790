$padding: 16px;

.menuButton {
  display: flex;
  flex-direction: column;
  padding: 10px $padding;
  cursor: pointer;

  span > div:first-child {
    color: var(--m-Neutral);
  }

  &~div {
    border: none !important;
  }
}

.containerMenu {
  padding: 4px $padding;

  .menuItem {
    display: flex;
    gap: 10px;
    padding: 10px 0;
    color: var(--m-Neutral);
    background: transparent;
    
    &:hover {
      color: var(--m-White);
      background: transparent;
    }
  }

  .fullDivider {
    margin-left: -$padding;
    margin-right: -$padding;
  }
}
