.container {

  .th {
    &.sortable {
      cursor: pointer;
    }
  }
  

  .reportName {
    cursor: pointer;

    .displayName {
      flex: auto;
      text-wrap: auto;
    }
  }
}
