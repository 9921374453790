.container {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.relative {
    position: relative;
    height: 100%;
  }

  .background {
    background: radial-gradient(#22202A, #1E1F32);
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
}
