.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .image {
    position: relative;
    border-radius: var(--borderRadius);
    overflow: hidden;
    min-height: 4rem;

    img {
      border-radius: inherit;
    }
  }

  .description {
    font-size: 16px;
  }

  .link {
    color: var(--m-Blue);
    text-align: right;
    font-weight: 500;
  }
}
