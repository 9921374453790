$zIndex: var(--zIndexPanel);

.container {
  position: absolute;
  z-index: $zIndex;
  top: var(--headerHeight);
  right: 0;
  width: 342px;
  max-width: 100%;
  height: calc(100vh - #{var(--headerHeight)});
  background: var(--menuColor);
  border-left: 1px solid var(--borderColor);
  padding: 14px;
  box-shadow: 1px 2px 12px 0px #00000080;
  animation: moveFromRight 0.2s;

  .body {
    
  }

  .menuItems {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .linkItem {
    position: relative;
    display: flex;
    padding: 14px 16px;
    gap: 10px;
    background: var(--m-Blackcurrant-50);
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    font-size: 16px;
    font-weight: 500;

    &.linkDisabled {
      opacity: 0.5;
      cursor: not-allowed; 
    }

    &.activeLink {
      .linkStatus {
        background: var(--m-Sienna);
      }
    }

    .linkStatus {
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: transparent;
      border-top-left-radius: var(--borderRadius);
      border-bottom-left-radius: var(--borderRadius);
    }
  }
}

.backdrop {
  position: absolute;
  z-index: calc(#{$zIndex} - 1);
  top: var(--headerHeight);
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - #{var(--headerHeight)});
  backdrop-filter: blur(1px);
}

@keyframes moveFromRight {
  from { transform: translateX(10%); }
  to { transform: translateX(0%); }
}
