.chakra-portal {
    #chakra-toast-manager-top-right {
        top: var(--headerHeight) !important;

        .chakra-toast {
            .chakra-toast__inner {
                > div {
                    position: relative;
                }

                .chakra-alert__icon {
                    display: none;
                }

                > div[data-status="error"] {
                    border: 1px solid #DB251A;
                    border-top-width: 5px;
                }
            }
            button[aria-label="Close"] {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;

                svg * {
                    fill: var(--m-Neutral-20);
                }
            }
        }
    }
}
