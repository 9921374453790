.container {

  .header {
    display: flex;
    align-items: center;
    background: var(--m-Neutral-70);
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 12px;

    .filename {
      font-size: 18px;
    }
  }

  .body {
    position: relative;
    padding: 0;
  }
  
  .footer {
    padding: 0;
    border-top: 1px solid var(--borderColor);
    box-shadow: black 0px 0px 12px 0px;
    z-index: 1;
    
    button {
      width: 100%;
      border-radius: 0;
    }
  }
}
