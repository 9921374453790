:root {
  /* Sizes */
  --headerHeight: 56px;
  --borderRadius: 4px;
  --leftSidebarWidth: 80px;
  --baseLeftPanelWidth: 500px;

  /* z-index */
  --zIndexPanel: 10;
  --zIndexHeaderMenu: 12;

  /* Colors */
  --borderColor: var(--m-Neutral-50);
  --menuColor:  var(--m-Blackcurrant-60);

  /* Colors UI Kit */
  --m-White: #FFFFFF;
  --m-Black: #000000;
  --m-Red: #DB251A;
  
  --m-Neutral: #B8B5C4;
  --m-Neutral-20: #736F88;
  --m-Neutral-40: #4C495B;
  --m-Neutral-50: #423F4D;
  --m-Neutral-60: #3A3743;
  --m-Neutral-70: #312E38;
  --m-Neutral-80: #2A2830;
  --m-Neutral-90: #222126;
  --m-Neutral-100: #19181C;

  --m-Blackcurrant-40: #4F4965;
  --m-Blackcurrant-50: #332E44; /* header-bg */
  --m-Blackcurrant-60: #292438;
  --m-Blackcurrant-70: #231E30;
  --m-Blackcurrant-80: #1A1624;

  --m-Blue30: #BFDDFE;
  --m-Blue10: #60ABFA;
  --m-Blue: #428DF6;
  --m-Blue-10: #256AEB;
  --m-Blue-20: #1E3E8A;

  --m-Sienna20: #FBC5AD;
  --m-Sienna10: #F89C79;
  --m-Sienna: #F56F4A;
  --m-Sienna-10: #F1431E;
  --m-Sienna-20: #E32913;
}
