.container {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh - #{var(--headerHeight)});
  margin: 0 auto;
  color: #fff;
  background: var(--m-Blackcurrant-60);
  box-shadow: 0 2px 64px rgba(0, 0, 0, 0.35);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);

  position: relative;
}
