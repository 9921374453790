.container {
  .button {
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    padding: 8px 12px;
    width: 250px;
    height: 40px;
  }

  .menu {
    width: 250px;
    padding: 0;
    border-radius: var(--borderRadius);
    overflow: hidden;
  }
}
