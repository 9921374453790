$baseIconHeight: 24;

.icon {
    display: flex !important;
    justify-content: center;
    align-items: center;
    // height: 100%;

    &.fillColor {
        svg {
            path {
                fill: currentColor;
            }
        }
    }

    @mixin size($size) {
        min-height: $size + px;
        svg {
            scale: calc($size/$baseIconHeight);
        }
    }

    &.size12 {
        @include size(12);
    }

    &.size16 {
        @include size(16);
    }

    &.size20 {
        @include size(20);
    }

    &.size24 {
        @include size(24);
    }

    &.size28 {
        @include size(28);
    }

    &.size32 {
        @include size(32);
    }

    &.clickable {
        cursor: pointer;
    }

}
