$zIndex: var(--zIndexPanel);

.container {
  position: absolute;
  z-index: $zIndex;
  top: var(--headerHeight);
  width: 342px;
  height: calc(100vh - #{var(--headerHeight)});
  border-left: 1px solid var(--borderColor);
  border-right: 1px solid var(--borderColor);
  background: var(--menuColor);
  padding: 14px;

  &.animated {
    &.positionLeft {
      left: 0;
      animation: moveFromLeft 0.2s;
    }
  
    &.positionRight {
      right: 0;
      animation: moveFromRight 0.2s;
    }
  }
}

@keyframes moveFromLeft {
  from { transform: translateX(-10%); }
  to { transform: translateX(0%); }
}

@keyframes moveFromRight {
  from { transform: translateX(10%); }
  to { transform: translateX(0%); }
}
