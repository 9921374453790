.container {
  position: relative;
  border: 1px solid var(--borderColor) !important;
  border-radius: var(--borderRadius) !important;
  border-collapse: separate;
  overflow-y: initial !important;
  overflow-x: initial !important;

  &.stickyHeader {
    overflow: auto !important;

    table {
      position: relative;
      z-index: 1;
  
      th {
        position: sticky;
        top: 0;
      }
    }
  }

  .table {
    position: relative;
    border: none !important;
    border-radius: var(--borderRadius) !important;
  }

  .thead {
    height: 60px;
    font-weight: 600;
    text-transform: initial;
  }

  .th {
    background-color: var(--m-Neutral-90) !important;
    color: var(--m-White);
    text-transform: initial;
    font-size: 14px;
    letter-spacing: normal;
  }

  .tr {
    &:nth-of-type(odd) {
      th, td {
        background: var(--m-Neutral-70);
        border-color: var(--borderColor);
        border-width: 1px;
      }
    }

    &:nth-of-type(even) {
      th, td {
        background: var(--m-Neutral-80);
        border-color: var(--borderColor);
        border-width: 1px;
      }
    }
  }

  .td {

  }
}
