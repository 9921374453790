.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: var(--headerHeight);
  background: var(--m-Blackcurrant-50);
  border-bottom: 1px solid var(--borderColor);
  overflow: hidden;

  .logo {
    display: flex;
    align-items: center;
    max-width: 250px;
    padding-left: 16px;

    svg {
      width: 196px;
      fill: #fff;
    }
  }

  .right {
    display: flex;
    align-items: center;

    >:not(:first-child) {
      border-left: 1px solid var(--borderColor);
    }

  }

  .button {
    cursor: pointer;
    padding: 16px;

    &:hover {
      
    }
  }
}
