.container {

  &[disabled] {
    background: var(--m-Neutral-20) !important;
  }

  &.button {}
  
  &.reset {}

  &.submit {
    background: var(--m-Sienna);

    &:hover {
      background: var(--m-Sienna10);
    }
  }

  &.transparent {
    background: transparent !important;
  }
}
