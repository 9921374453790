.container {
  width: 56px;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--borderColor);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    background: var(--m-Sienna);
  }
}
