$nameHeight: 64px;

.container {
  border: 1px solid var(--borderColor);
  border-radius: 12px;

  width: 290px;
  height: $nameHeight;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  background: var(--m-Neutral-20);

  .itemHeader {
    display: flex;
    gap: 8px;
    align-items: center;
    background: var(--m-Neutral-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 12px 16px;
  }

  .itemHeader {
    cursor: pointer;
    height: $nameHeight;

    .title {
      color: var(--m-White);
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .timeUpdated {
      font-size: 13px;
      color: var(--m-Neutral);
    }
  }

}
