.container {

  .arrowIconDown {
    fill: var(--m-Neutral);
    transition: all 0.2s;

    &.iconUp {
      transform: rotate(180deg);
    }
  }

  .itemRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    line-height: 24px;

    .itemRowLeft {
      cursor: pointer;
      flex: auto;
      padding: 2px 0;
      display: flex;
      gap: inherit;
      align-items: inherit;

      .itemName {
        width: 100%;
      }
    }
  
    .itemRowRight {
      
    }
  }

  

  .itemIcon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.checkBoxIconWrapper {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  overflow: visible;
  position: relative;
}

.whiteWrapper {
  background: white;
  border-radius: 4px;
}
