.container {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--leftSidebarWidth);
  height: 100%;
  background-color: var(--menuColor);
  opacity: 80%;
  padding: 10px 0;
  z-index: 11;

  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
