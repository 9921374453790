$titleHeight: 56px;

.container {
  top: 0;
  left: var(--leftSidebarWidth) !important;
  padding: 0;
  width: var(--baseLeftPanelWidth);
  max-width: calc(100% - #{var(--leftSidebarWidth)});
  background: var(--m-Blackcurrant-50);

  .closeBtn {
    width: $titleHeight;
    height: $titleHeight;
    background: transparent;
    
    &:hover {
      background: transparent;
      * {
        fill: var(--m-Sienna-10) !important;
        color: var(--m-Sienna-10) !important;
      }
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    padding-left: 0;
    border-bottom: 1px solid var(--borderColor);
    height: $titleHeight;
  }

  .title {
    display: flex;
    color: var(--m-White);
    font-size: 16px;
    font-weight: 600;
  }

  .body {
    position: relative;
    overflow: auto;
    height: calc(100% - #{$titleHeight});
    background: var(--menuColor);
  }
}
