$footerHeight: 52px;

.container {
 
  .filtersList {
    overflow: auto;
    height: calc(100% - #{$footerHeight});
    position: relative;
  }

  .footer {
    position: absolute;
    display: flex;
    width: 100%;
    height: $footerHeight;
    bottom: 0;
    z-index: 1;
    box-shadow: 0px -4px 8px rgba($color: #000000, $alpha: 0.5);
  }
}
