$nameHeight: 64px;
$footerHeight: 64px;

.container {
  border: 1px solid var(--borderColor);
  border-radius: 12px;

  width: 290px;
  height: 340px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  background: var(--m-Neutral-20);

  .itemImage {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 1 calc(100% - #{$nameHeight} - #{$footerHeight});
    height: auto;
    overflow: auto;

    img {
      height: 100%;
    }
  }

  .itemHeader,
  .itemFooter {
    display: flex;
    gap: 8px;
    align-items: center;
    background: var(--m-Neutral-80);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 12px 16px;
  }

  .itemHeader {
    cursor: pointer;
    height: $nameHeight;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .itemFooter {
    height: $footerHeight;
    color: var(--m-Neutral);
    font-size: 14px;
  }
}
