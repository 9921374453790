.container {
  position: absolute;
  right: 0;

  .drawerContainer {
    position: relative;

    :global(.chakra-modal__content-container) {
      position: relative;
      width: 0;
      height: 0;
    }
  }
}

.drawerContent {
  width: 420px !important;
  height: calc(100vh - #{var(--headerHeight)});
  top: initial !important;
  bottom: 0;

  .header {
    height: var(--headerHeight);
    background: var(--m-Neutral-70);
    border: 1px solid var(--borderColor);

    font-size: 14px;
    font-weight: 600;

    display: flex;
    align-items: center;

    .title {
      line-height: 24px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      -webkit-box-orient: vertical;
    }
  }

  .body {
      background: var(--m-Neutral-60);
      padding: 20px;
  }
}
