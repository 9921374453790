$paddingSide: 20px;

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--borderColor);

    .header {
        position: relative;
        background: #8465DE4D;
        display: flex;
        align-items: center;
        gap: 12px;
        padding-left: $paddingSide;
        padding-right: $paddingSide;
        height: 44px;
        color: var(--m-Neutral);
        font-weight: 500;

        display: flex;
        justify-content: space-between;
    }

    .marker {
        position: absolute;
        left: 0;
        width: 6px;
        height: 100%;
        background: var(--m-Sienna);
    }

    .body {
        padding-left: $paddingSide;
        padding-right: $paddingSide;
        color: var(--m-Neutral);
        display: flex;
        flex-direction: column;
        gap: 8px;

        .bodyFooter {
            display: flex;
            justify-content: space-between;
            line-height: 18px;
            height: 20px;
        }
    }

    .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--m-White);
    }

    .time {
        line-height: inherit;
        font-weight: 500;
    }

    .link {
        cursor: pointer;
        display: inline-flex;
        color: var(--m-Blue);
        text-align: right;
        font-weight: 600;
        line-height: inherit;
        height: inherit;

        * {
            line-height: inherit;
        }
    }
}
