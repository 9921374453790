.container {
  display: flex;
  align-items: center;
  gap: 8px;
  
  .item {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
    color: var(--m-White);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    &:last-child {
      color: var(--m-Neutral);

      * {
        fill: var(--m-Neutral);
      }
    }
  }
}
