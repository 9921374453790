.container {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid var(--borderColor);
  background: var(--m-Blackcurrant-50);

  .titleWrapper {
    display: flex;
    gap: 12px;
    align-items: center;

    * {
      cursor: pointer;
    }
  }

  .title {
    cursor: pointer;
    font-size: 14px;
  }

  .count {
    color: var(--m-Neutral);
  }

  .downIcon {
    cursor: pointer;
    transition: all 0.2s;
    
    &.isUp {
      transform: rotate(180deg);
    }
  }
}
