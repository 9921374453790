.container {
  border: 1px solid var(--m-Neutral-40);
  border-radius: var(--borderRadius);
  overflow: auto;

  .cell {
    position: relative;
    padding: 12px 16px;
    display: flex;
    align-items: start;
    gap: 8px;

    &:not(:last-child) {
      border-bottom: inherit;
    }

    .marker {
      position: absolute;
      width: 6px;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--m-Sienna);
    }

  }

}
